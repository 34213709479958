'use client';

import { AnimatePresence, motion } from 'motion/react';
import cn from 'mxcn';
import { useField } from './use-field';
import { AlertCircle } from 'lucide-react';
export default function FieldError(props: {
  className?: string;
}) {
  const field = useField();
  let errors = field.serverErrors;
  if (field.blurred && field.localErrors.length > 0) {
    errors = errors.concat(field.localErrors);
  }
  return <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-component="FieldError" data-sentry-source-file="field-error.tsx">
      {field.blurred && errors.length > 0 && <motion.div animate={{
      opacity: 1
    }} initial={{
      opacity: 0
    }} id={field.id + '-errors'} role="alert" className={cn('row-1 items-center relative bg-yellow-600 text-white rounded-md p-1 leading-tighter transition-all hover:w-max truncate z-20', props.className)}>
          <AlertCircle className="size-4" />

          <div className="text-xs flex-auto truncate">
            {errors.map((e, i) => <div key={i}>{e.message}</div>)}
          </div>
        </motion.div>}
    </AnimatePresence>;
}