'use client';

import { ComponentPropsWithoutRef } from 'react';
import Field from './field';
import { Label } from '../label';
import cn from 'mxcn';
import FieldError from './field-error';
import { sentenceCase } from 'change-case';
export default function StandardField<T>({
  children,
  hideLabel,
  className,
  ...props
}: ComponentPropsWithoutRef<typeof Field<T>> & {
  label?: React.ReactNode;
  hideLabel?: boolean;
  className?: string;
  description?: React.ReactNode;
}) {
  return <Field {...props} data-sentry-element="Field" data-sentry-component="StandardField" data-sentry-source-file="standard-field.tsx">
      {({
      field
    }) => <>
          <div className={cn('flex flex-col relative py-1 shrink cursor-default mr-auto', hideLabel && !props.description && 'absolute', className)}>
            <Label className={cn('font-medium text-contrast-700 leading-snug cursor-[inherit] flex flex-col gap-0.5', hideLabel && 'sr-only absolute')}>
              {props.label ?? sentenceCase(field.name)}
              {props.description && <div className="font-normal text-sm text-muted">
                  {props.description}
                </div>}
              <FieldError className="absolute left-full top-1/2 -translate-y-1/2 mx-2" />
            </Label>
          </div>
          {typeof children === 'function' ? children({
        field
      }) : children}
        </>}
    </Field>;
}